import React from "react"
import Login from "../features/login/Login"
import Layout from "../components/layout"
import { AnonymousOnlyRoute } from "../features/routing/Routes"

const LoginPage = () => {

  return (
    <Layout>
      <AnonymousOnlyRoute component={Login}/>
    </Layout>
  )
}

export default LoginPage