import { LoginDetails, LoginFormErrors } from "./types"
import { isValidString, validateEmail } from "../../utils"

export default function validate(loginDetails: LoginDetails): LoginFormErrors {
  let errors: LoginFormErrors = {}

  if (!isValidString(loginDetails.email)) {
    errors.email = "Please enter your email"
  } else if (!validateEmail(loginDetails.email)) {
    errors.email = "Email address is invalid"
  }
  if (!isValidString(loginDetails.password)) {
    errors.password = "Please enter your password"
  }

  return errors
};